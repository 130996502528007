import React from 'react';
import { useTranslation } from 'react-i18next';

import {
	useTheme,
	ListItemText,
	useMediaQuery,
	TableHead,
	TableRow,
	TableCell,
	Button,
	Box,
	LinearProgress,
} from '@mui/material';

import { Face as Avatar } from '@mui/icons-material';

import ResponsiveTable from 'components/ResponsiveTable';

import { rowsPerPageOptions } from 'config/constants';

import { useNavigate } from 'react-router-dom';
import { formatCUIT } from 'lib/helpers';

type Props = {
	loading?: boolean;
	access: Access[];
	handleChangePage: (_: unknown, newPage: number) => any;
	handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => any;
	page: number;
	rowsPerPage: number;
};

export default function AccessTable({
	loading,
	access,
	handleChangePage,
	handleChangeRowsPerPage,
	page,
	rowsPerPage,
}: Props) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	function onDetail(selected: Access) {
		const { code } = selected;
		navigate(`/access/${code}`);
	}

	return (
		<Box sx={{ maxHeight: '50vh' }}>
			{!!loading && <LinearProgress />}

			<ResponsiveTable
				elements={isMobile ? access : access.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
				list={{
					primaryKey: '_id',
					avatar: <Avatar />,
					renderListItemText: (acc) => {
						return (
							<ListItemText
								onClick={() => onDetail(acc)}
								primary={`${acc.code}`}
								secondary={
									<Box>
										<address>
											<a href={`mailto:${acc.code}`} target="_blank" rel="noreferrer">
												{acc.code}
											</a>
										</address>
									</Box>
								}
							/>
						);
					},
				}}
				table={{
					renderHead: () => (
						<TableHead>
							<TableRow>
								<TableCell align="left" padding="normal">
									{t('access:code')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('access:stay')}
								</TableCell>
								<TableCell align="left" padding="normal">
									Nro ID
								</TableCell>
								<TableCell align="left" padding="normal">
									Descripción
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('access:type')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('access:plate')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('access:last_entry')}
								</TableCell>

								<TableCell align="left" padding="normal">
									{t('access:last_exit')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:actions')}
								</TableCell>
							</TableRow>
						</TableHead>
					),
					renderRow: (acc) => {
						return (
							<TableRow tabIndex={-1} key={acc._id}>
								<TableCell>{acc.code}</TableCell>
								<TableCell>{t(`access:${acc.stay}`)}</TableCell>
								<TableCell>{formatCUIT(acc.tent)}</TableCell>
								<TableCell>{acc.description || '-'}</TableCell>
								<TableCell>{acc.type || '-'}</TableCell>
								<TableCell>
									{/* {acc.plate.length > 0 ? acc.plate.map((p) => <>{p}</>) : '-'} */}
									{acc.plate.join(' | ')}
								</TableCell>
								<TableCell>{lastMovements(acc.movements, 'entry')}</TableCell>
								<TableCell>{lastMovements(acc.movements, 'exit')}</TableCell>
								<TableCell>
									<Box>
										<Button onClick={() => onDetail(acc)} variant="outlined">
											{t(`common:details`)}
										</Button>
									</Box>
								</TableCell>
							</TableRow>
						);
					},
					pagination: {
						count: access.length,
						page,
						rowsPerPage,
						rowsPerPageOptions,
						onPageChange: handleChangePage,
						onRowsPerPageChange: handleChangeRowsPerPage,
					},
				}}
			/>
		</Box>
	);
}

function parseDate(dateString: string | undefined) {
	if (!dateString) return '-';
	const date = new Date(dateString);

	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();
	const hours = date.getHours();
	const minutes = date.getMinutes();

	const parsedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year} ${hours
		.toString()
		.padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
	return parsedDate;
}

function lastMovements(movements: Movement[], type: string) {
	const filteredMovements: Movement[] = movements.filter((movement) => movement.type === type);

	const lastMovement = filteredMovements.length > 0 ? filteredMovements.at(-1) : null;

	return lastMovement ? parseDate(lastMovement.date) : '-';
}
