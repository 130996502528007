import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListItemText, TableHead, TableRow, TableCell, Box, LinearProgress, Typography } from '@mui/material';

import ResponsiveTable from 'components/ResponsiveTable';

import { rowsPerPageOptions } from 'config/constants';

import moment from 'moment';

type Props = {
	loading?: boolean;
	refetch: () => any;
	data: PaginateSource<Candidate>;
	handleChangePage: (_: unknown, newPage: number) => any;
	handleChangeRowsPerPage: (candidate: React.ChangeEvent<HTMLInputElement>) => any;
	page: number;
	rowsPerPage: number;
};

export default function CandidatesList({
	refetch,
	data,
	handleChangePage,
	handleChangeRowsPerPage,
	page,
	rowsPerPage,
	loading,
}: Props) {
	const { t } = useTranslation();

	return (
		<Box sx={{ maxHeight: '50vh' }}>
			{!!loading && <LinearProgress />}

			<ResponsiveTable
				elements={data.elements}
				listSx={{ maxHeight: '70vh' }}
				tableSx={{ maxHeight: '65vh' }}
				list={{
					primaryKey: '_id',
					renderListItemText: (candidate) => {
						return (
							<ListItemText
								primary={
									<Box>
										<Typography>{candidate.name}</Typography>
										<Typography>{candidate.surname}</Typography>
										<Typography>{candidate.email}</Typography>
										<Typography>{candidate.phone}</Typography>
										<Typography>{moment(candidate.createdAt).format('DD/MM/YYYY hh:mm')}</Typography>
									</Box>
								}
							/>
						);
					},
				}}
				table={{
					renderHead: () => (
						<TableHead>
							<TableRow>
								<TableCell align="left" padding="normal">
									{t('common:name')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:lastName')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:email')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:phone')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:date')}
								</TableCell>
							</TableRow>
						</TableHead>
					),
					renderRow: (notification) => {
						return (
							<TableRow tabIndex={-1} key={notification._id}>
								<TableCell>{notification.name}</TableCell>
								<TableCell>{notification.surname}</TableCell>
								<TableCell>{notification.email}</TableCell>
								<TableCell>{notification.phone}</TableCell>
								<TableCell>{moment(notification.createdAt).format('DD/MM/YYYY')}</TableCell>
							</TableRow>
						);
					},
					pagination: {
						count: data.total,
						page,
						rowsPerPage,
						rowsPerPageOptions,
						onPageChange: handleChangePage,
						onRowsPerPageChange: handleChangeRowsPerPage,
					},
				}}
			/>
		</Box>
	);
}
