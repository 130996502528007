import { API_DOMAIN, CANDIDATES } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus, getSearch } from 'lib/helpers';

import { z } from 'zod';

export const zCandidate = z.object({
	_id: z.optional(z.string()),
	name: z.string(),
	surname: z.string(),
	email: z.string(),
	phone: z.string(),
	linkedin: z.string(),
	createdAt: z.optional(z.string()),
});

const zCandidateArrayResponse = z.object({
	total: z.number(),
	// We only need to validate the first element, if its ok, we assume that the rest are ok
	elements: z.custom<Candidate[]>((data) => {
		if (Array.isArray(data) && data.length > 0) {
			const parsed = zCandidate.safeParse(data[0]);
			if (!parsed.success) {
				console.error(`${parsed.error}`);
			}
			return parsed.success;
		}
		if (!Array.isArray(data)) {
			console.error(`response should be a valid array`);
			return false;
		}
		return true;
	}),
});

export type FetchCandidateSearchProps = {
	search?: string | null;
	topic?: string | null;
};

export type FetchCandidatesProps = FetchCandidateSearchProps & PaginationProps;

export async function fetchCandidates(props: FetchCandidatesProps): Promise<PaginateSource<Candidate>> {
	try {
		const sort: Sort = {
			[props.orderBy || 'title']: props.order || 'asc',
		};

		const { filter, options } = getSearch(sort, props);

		const response = await api.get(`${API_DOMAIN}${CANDIDATES}`, {
			params: { filter, options },
		});
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = zCandidateArrayResponse.parse(response.data);
		return data;
	} catch (error) {
		console.error(error);
		return { elements: [], total: 0 };
	}
}
