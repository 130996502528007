import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { FetchAppCommentProps } from 'lib/models/appComments';

import useAppComments from 'hooks/useAppComments';

import ScreenContainer from 'components/containers/ScreenContainer';
import SearchToolbar from 'components/SearchToolbar';
import AppCommentsList from 'components/AppCommentsList';

function AppComments() {
	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams({
		search: '',
		page: '0',
		rowsPerPage: '25',
	});

	const [search, setSearch] = useState(searchParams.get('search') || '');
	const [page, setPage] = useState(Number(searchParams.get('page') || 0));
	const [rowsPerPage, setRowsPerPage] = useState(Number(searchParams.get('rowsPerPage') || 10));

	const searchProps = useMemo(() => {
		let searchParams: FetchAppCommentProps = { page, rowsPerPage, search };
		return searchParams;
	}, [page, rowsPerPage, search]);

	const { comments: data, loading, refetch } = useAppComments(searchProps);

	const handleChangePage = (_: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	function onSearch(value: string) {
		setSearch(value);
	}

	useEffect(() => {
		setSearchParams({ page: `${page}`, rowsPerPage: `${rowsPerPage}`, search: `${search}` });
	}, [setSearchParams, page, rowsPerPage, search]);

	const title = t('common:comments');

	return (
		<ScreenContainer title={title} goBack={true}>
			<Box sx={{ display: 'flex', flexDirection: 'row' }}>
				<SearchToolbar onSearch={onSearch} initialValue={search} />
			</Box>

			<Box>
				<AppCommentsList
					refetch={refetch}
					data={data}
					loading={loading}
					handleChangePage={handleChangePage}
					handleChangeRowsPerPage={handleChangeRowsPerPage}
					page={page}
					rowsPerPage={rowsPerPage}
				/>
			</Box>
		</ScreenContainer>
	);
}

export default AppComments;
