import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import ScreenContainer from 'components/containers/ScreenContainer';
import PaperContainer from 'components/containers/PaperContainer';

import { useEffect } from 'react';

import AccessDetailTable from 'components/AccessDetailTable';

import useAccessDetail from 'hooks/useAccessDetail';
import { formatCUIT } from 'lib/helpers';

function AccessDetail() {
	const { t } = useTranslation();

	const { code } = useParams<{ code: string }>();

	const navigate = useNavigate();

	const { access, loading, error } = useAccessDetail();

	const withDateRange = access?.stay === 'tent' || access?.stay === 'supplier';

	const title =
		loading || error
			? t('common:loading')
			: withDateRange
			? `Código: ${access.code} | ${access.stay === 'tent' ? 'Nro. de carpa:' : 'CUIT:'} ${formatCUIT(access.tent)} | ${
					access.description
			  }`
			: `Código: ${access.code}`;

	useEffect(() => {
		if (error) {
		}
	}, [error, navigate]);

	return (
		<ScreenContainer title={title} goBack={true}>
			<PaperContainer>
				<AccessDetailTable code={code || ''} initialize={access} />
			</PaperContainer>
		</ScreenContainer>
	);
}

export default AccessDetail;
