import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import style from './AccessTickets.module.css';
import { formatCUIT } from 'lib/helpers';

interface Props {
	access: Access;
	seeToggleButton: boolean;
	logoMute: string;
	sponsor: string;
	onChangeStatus: (id?: string) => void;
}
const A5Access = ({ access, seeToggleButton, logoMute, onChangeStatus, sponsor }: Props) => {
	const { t } = useTranslation();

	const withDateRange = access.stay === 'tent' || access.stay === 'supplier';

	return (
		<Grid item lg={6}>
			<div className={`${style.ticket} a5Ticket`}>
				<div className={style.side} style={withDateRange ? { paddingBottom: '30px' } : {}}>
					<span className={style.sideText}>{access.code}</span>
					<span className={style.sideText}>
						{withDateRange
							? `${parseDate(access.validDate.from)} - ${parseDate(access.validDate.to)}`
							: `${parseDate(access.validDate.from)}`}
					</span>
				</div>

				<div className={style.mainContent}>
					<span className={style.notValid}>Ticket no válido como factura</span>
					<div className={style.header}>
						{seeToggleButton && (
							<Button
								className="changeAccessStatus"
								variant="contained"
								sx={{
									position: 'absolute',
									right: '0',
									left: '0',
									width: '7rem',
									height: '1rem',
									marginLeft: 'auto',
									marginRight: 'auto',
									fontSize: '.8rem',
									display: 'flex',
								}}
								id="changeAccessStatus"
								onClick={() => onChangeStatus(access._id)}
								// onClick={() => setOnPrint((prev) => !prev)}
							>
								{access.isActive ? t('access:disable') : t('access:enable')}
							</Button>
						)}

						<div className={style.logoContainer}>
							<img src={logoMute} className={style.muteLogo} alt="Logo mute" />
							<div className={style.sponsorContainer}>
								{sponsor && (
									<img src={sponsor} style={{ width: '100%', height: 'auto', verticalAlign: 'center' }} alt="sponsor" />
								)}
							</div>
						</div>
						<div className={style.dateContainer}>
							<span>{`${t(`access:${access.stay}`)} - ${
								withDateRange ? `ID: ${formatCUIT(access.tent)}` : access.type
							}`}</span>
							<span>
								{withDateRange
									? `${parseDate(access.validDate.from)} - ${parseDate(access.validDate.to)}`
									: `${parseDate(access.validDate.from)}`}
							</span>
						</div>
					</div>
					<div className={style.qrContainer}>
						<div
							style={{
								border: '3px solid black',
								padding: '3px',
								display: 'flex',
								justifyContent: 'center',
								alignContent: 'center',
							}}
						>
							<QRCode size={155} value={access.code} />
						</div>
						<span>{access.code}</span>
					</div>
					<div className={style.footer}>
						<span>El establecimiento no se responsabiliza por roturas, faltantes,robos o hurtos.</span>
						<span>La seguridad de los vehículos es responsabilidad de sus titulares.</span>
						<span>La casa se reserva el derecho de admisión y permanencia</span>
						<span>El estacionamiento no incluye el uso de baños</span>
					</div>
				</div>
			</div>
		</Grid>
	);
};

export default A5Access;

function parseDate(dateString?: string) {
	if (dateString) {
		const date = new Date(dateString);
		return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
	}
	return '-';
}
