import { FetchArtistProps, fetchArtists } from 'lib/models/artists';
import { useCallback, useEffect, useState } from 'react';

function useArtists(props: FetchArtistProps) {
	const [loading, setLoading] = useState<boolean>(true);
	const [artists, setArtists] = useState<PaginateSource<Artist>>({ total: 0, elements: [] });

	const refetch = useCallback(async () => {
		const response = await fetchArtists(props);
		setArtists(response);
		setLoading(false);
	}, [props]);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, artists, refetch, setLoading };
}

export default useArtists;
