import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListItemText, TableHead, TableRow, TableCell, Box, LinearProgress, Typography } from '@mui/material';

import ResponsiveTable from 'components/ResponsiveTable';

import { rowsPerPageOptions } from 'config/constants';
import moment from 'moment';

type Props = {
	loading?: boolean;
	refetch: () => any;
	data: PaginateSource<AppComment>;
	handleChangePage: (_: unknown, newPage: number) => any;
	handleChangeRowsPerPage: (comment: React.ChangeEvent<HTMLInputElement>) => any;
	page: number;
	rowsPerPage: number;
};

export default function AppCommentsList({
	data,
	handleChangePage,
	handleChangeRowsPerPage,
	page,
	rowsPerPage,
	loading,
}: Props) {
	const { t } = useTranslation();

	return (
		<Box sx={{ maxHeight: '50vh' }}>
			{!!loading && <LinearProgress />}

			<ResponsiveTable
				elements={data.elements}
				listSx={{ maxHeight: '70vh' }}
				tableSx={{ maxHeight: '65vh' }}
				list={{
					primaryKey: '_id',
					renderListItemText: (comment) => {
						return (
							<ListItemText
								primary={
									<Box>
										<Typography>
											{comment.user?.first_name
												? `${comment.user.first_name} ${comment.user.last_name}`
												: comment.username}
										</Typography>
										<Typography>{comment.user?.email}</Typography>
										<Typography>{comment.comment}</Typography>
										<Typography>{moment(comment.createdAt).format('DD/MM/yyyy hh:mm')}</Typography>
									</Box>
								}
							/>
						);
					},
				}}
				table={{
					renderHead: () => (
						<TableHead>
							<TableRow>
								<TableCell align="left" padding="normal">
									{t('common:user').toUpperCase()}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:email').toUpperCase()}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:comment').toUpperCase()}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:date').toUpperCase()}
								</TableCell>
							</TableRow>
						</TableHead>
					),
					renderRow: (comment) => {
						return (
							<TableRow tabIndex={-1} key={comment._id}>
								<TableCell>
									{comment.user?.first_name ? `${comment.user.first_name} ${comment.user.last_name}` : comment.username}
								</TableCell>
								<TableCell>{comment.user?.email}</TableCell>
								<TableCell>{comment.comment}</TableCell>
								<TableCell>{moment(comment.createdAt).format('DD/MM/yyyy hh:mm')}</TableCell>
							</TableRow>
						);
					},
					pagination: {
						count: data.total,
						page,
						rowsPerPage,
						rowsPerPageOptions,
						onPageChange: handleChangePage,
						onRowsPerPageChange: handleChangeRowsPerPage,
					},
				}}
			/>
		</Box>
	);
}
