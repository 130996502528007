import { Box, LinearProgress } from '@mui/material';
import AccessFilter from 'components/AccessFilter';
import AccessTickets from 'components/AccessTickets';
import ScreenContainer from 'components/containers/ScreenContainer';
import useAccess from 'hooks/useAccess';
import useSponsor from 'hooks/useSponsor';
import { FetchAccessProps } from 'lib/models/access';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export type Format = '6xp' | '1xp';

function QRCodes() {
	const { t } = useTranslation();
	const { sponsor } = useSponsor();

	const [searchParams, setSearchParams] = useSearchParams({
		text: '',
		isAssigned: '',
		date: '',
	});
	const [format, setFormat] = useState<Format>('6xp');

	const [params, setParams] = useState({
		page: Number(searchParams.get('page') || 0),
		rowsPerPage: Number(searchParams.get('rowsPerPage') || 25),
		text: searchParams.get('text') || '',
		isAssigned: searchParams.get('isAssigned') || 'any',
		stay: searchParams.get('stay') || 'all',
		from: moment(searchParams.get('from') || moment()),
		to: moment(searchParams.get('to') || moment()),
	});

	const handleParams = (value: any, key: string) => {
		setParams({ ...params, [key]: value });
	};

	const searchProps: FetchAccessProps = useMemo(() => {
		const { text, isAssigned, stay, from, to } = params;
		let searchParams = { text, isAssigned, stay, from: from.toISOString(), to: to.toISOString() };
		return searchParams;
	}, [params]);

	const { access, refetch, loading } = useAccess(searchProps);

	const [loadingPDF, setLoadingPDF] = useState(false);

	const onSearch = async () => {
		refetch();
	};

	useEffect(() => {
		const { page, rowsPerPage, text, isAssigned, from, to, stay } = params;
		setSearchParams({
			page: `${page}`,
			rowsPerPage: `${rowsPerPage}`,
			text: `${text}`,
			isAssigned: `${isAssigned}`,
			stay: `${stay}`,
			from: `${from.format('YYYY-MM-DD')}`,
			to: `${to.format('YYYY-MM-DD')}`,
		});
	}, [setSearchParams, params]);

	return (
		<ScreenContainer title={t('common:QRCodes')}>
			{(!!loading || !!loadingPDF) && <LinearProgress />}

			<Box>
				<Box sx={{ paddingTop: '1rem' }}>
					<AccessFilter
						handleParams={handleParams}
						isAssigned={params.isAssigned}
						text={params.text}
						stay={params.stay}
						from={params.from}
						to={params.to}
						onSearch={onSearch}
						refetch={refetch}
						handleFormat={(format: Format) => setFormat(format)}
						format={format}
					/>
				</Box>
				<AccessTickets
					accessCodes={access}
					date={{ start: params.from.format('DD-MM-YYYY'), end: params.to.format('DD-MM-YYYY') }}
					refetch={refetch}
					loading={loading}
					setLoading={setLoadingPDF}
					sponsor={sponsor}
					format={format}
				/>
			</Box>
		</ScreenContainer>
	);
}

export default QRCodes;
