import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';
import SectionModal from 'components/SectionModal';
import SectionsList from 'components/SectionsList';

import usePopulatedSections from 'hooks/usePopulatedSections';
import { SectionType } from 'lib/models/sections';

export default function Sections() {
	const defaultCategory: PopulatedProductSection = {
		title: '',
		allowsPromotionPrice: true,
		categories: [],
		type: SectionType.OTHER,
	};

	const { t } = useTranslation();

	const title = t('product:sections');

	const { loading, sections, refetch } = usePopulatedSections();

	const [showModal, setShowModal] = useState<PopulatedProductSection | null>(null);

	const onEdit = (productCategory: PopulatedProductSection) => {
		setShowModal(productCategory);
	};

	const onClose = () => {
		refetch();
		setShowModal(null);
	};

	return (
		<ScreenContainer title={title} goBack={true}>
			<CreateIcon onCreate={() => setShowModal(defaultCategory)} disabled={loading} />
			{showModal && <SectionModal defaultValue={showModal} onClose={onClose} refetch={refetch} />}
			<Box>
				<SectionsList onEdit={onEdit} refetch={refetch} data={sections} loading={loading} />
			</Box>
		</ScreenContainer>
	);
}
