const access = {
	access: 'Accesos',
	tent: 'Carpa',
	night: 'Noche',
	day: 'Día',
	quantityPerDay: 'Cantidad por día',
	scan_code: 'Escanear código',
	generate_code: 'Generar código',
	print: 'Imprimir',
	tent_number: 'Número de carpa',
	start_date: 'Fecha de llegada',
	end_date: 'Fecha de salida',
	generate: 'Generar',
	manually_enter: 'Ingresar manualmente',
	code: 'Código',
	type: 'Tipo',
	stay: 'Estadía',
	plate: 'Patente',
	enter_code: 'Ingresar código',
	enter_plate: 'Ingresar patente',
	plate_number: 'Número de patente',
	invalid_code_or_plate: 'Código inexistente o patente desconocida',
	associated_code: 'Código asociado a patente:',
	associated_code_with_plate: 'Código {{code}} asociado a patente: {{plate}}',
	scan_entry: 'Escanear ingreso',
	scan_exit: 'Escanear salida',
	last_entry: 'Último ingreso',
	last_exit: 'Última salida',
	unregistered_plate: 'Patente no registrada',
	accessTitle: 'Acceso: {{code}}',
	accessDetailTitle: 'Patente {{plate}}',
	entry: 'Ingreso',
	entries: 'Ingresos',
	exit: 'Salida',
	exits: 'Salidas',
	entrySuccess: 'Ingreso registrado',
	exitSuccess: 'Salida registrada',
	exitError: 'Error al registrar salida',
	outOfDate: 'Fuera de fecha',
	assigned: 'Asignada',
	unassigned: 'Sin asignar',
	accessWithoutPrevEntry: 'Vehiculo sin ingreso previo',
	enterPlateLater: 'Puedes continuar e ingresar la patente más tarde',
	addPlate: 'Agregar patente',
	enable: 'Habilitar',
	disable: 'Inhabilitar',
	noEnqueuedScans: 'No hay escaneos en fila',
	savedInLocalStorage: 'Guardado en almacenamiento local',
	offlineScanning: 'Escaneo sin conexión',
	assignPlate: 'Asignar patente',
	optional: 'Opcional',
	synchronizedAccess: 'Accesos sincronizados',
	sync: 'Sincronizar',
	errors: 'Errores',
	errorOnSync: 'Ocurrió un error al sincronizar',
	all: 'Todos',
	from: 'Desde',
	to: 'Hasta',
	changeSponsor: 'Cambiar sponsor',
	errorOnPDF: 'Error al generar PDF',
	updatedAccess: 'Acceso actualizado',
	errorOnUpdate: 'Error al actualizar el acceso',
	alreadyUsed: 'QR ya utilizado',
	slots: 'Cantidad de cocheras',
	supplier: 'Proveedor',
};

export default access;
