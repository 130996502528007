import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Delete, Edit } from '@mui/icons-material';
import { Box, LinearProgress, Typography } from '@mui/material';

import useSuccessSnackbar from 'hooks/useSuccessSnakbar';

import { enqueueSnackbarError } from 'lib/helpers';
import { deleteProductCategory } from 'lib/models/productCategories';

import ConfirmationDialog from 'components/ConfirmationDialog';
import PaperContainer from 'components/containers/PaperContainer';
import { price } from 'lib/numbers';

type Props = {
	loading: boolean;
	data: PopulatedProductCategory[];
	onEdit: (productCategory: PopulatedProductCategory) => void;
	refetch: () => void;
};

export default function ProductCategoriesList({ loading, data, onEdit, refetch }: Props) {
	const { t } = useTranslation();
	const successSnackbar = useSuccessSnackbar();

	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<PopulatedProductCategory | null>(null);

	const onDelete = (productCategory: PopulatedProductCategory) => {
		setShowDeleteConfirmation(productCategory);
	};

	const onDeleteHandler = async (mustDelete: boolean) => {
		if (mustDelete && showDeleteConfirmation?._id) {
			if (showDeleteConfirmation.items.length > 0) {
				setShowDeleteConfirmation(null);

				return enqueueSnackbarError(t('product:errorHasItems'));
			}
			const deleted = await deleteProductCategory(showDeleteConfirmation);

			if (!deleted) {
				enqueueSnackbarError(t('product:deletionError'));
			} else {
				successSnackbar();
				refetch();
			}
		}

		setShowDeleteConfirmation(null);
	};
	return (
		<Box>
			{loading && <LinearProgress sx={{ mb: 3 }} />}
			{showDeleteConfirmation && (
				<ConfirmationDialog
					title={t('common:deleteTitle')}
					description={t('common:deleteText')}
					onClose={onDeleteHandler}
				/>
			)}
			<Box>
				{data.length === 0 && <Typography>{t('common:withoutElements')}</Typography>}
				{data.map((el) => {
					return (
						<PaperContainer
							key={el._id}
							sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center',
								mb: 2,
								position: 'relative',
							}}
						>
							<Box>
								<Typography sx={{ mb: 2 }}>
									<strong style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
										{el.title} {!!el.vip && <Typography sx={{ textDecoration: 'underline' }}> VIP</Typography>}
									</strong>
								</Typography>
								<Typography sx={{ mb: 2 }}>{el.description}</Typography>
								{el.items.map((item) => {
									return <Typography sx={{ mb: 2 }}>{`${item.title} (${price(item.price ?? 0)})`}</Typography>;
								})}
							</Box>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'flex-end',
									gap: 1,
									position: 'absolute',
									top: 16,
									right: 16,
								}}
							>
								<Edit sx={{ cursor: 'pointer' }} onClick={() => onEdit(el)} color="primary" />

								<Delete sx={{ cursor: 'pointer' }} onClick={() => onDelete(el)} color="secondary" />
							</Box>
						</PaperContainer>
					);
				})}
			</Box>
		</Box>
	);
}
