import { useCallback, useEffect, useState } from 'react';

import { FetchCandidatesProps, fetchCandidates } from 'lib/models/candidates';

function useCandidates(props: FetchCandidatesProps) {
	const [loading, setLoading] = useState<boolean>(true);

	const [candidates, setCandidates] = useState<PaginateSource<Candidate>>({ total: 0, elements: [] });

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchCandidates(props);
		setCandidates(response);
		setLoading(false);
	}, [props]);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, candidates, refetch };
}

export default useCandidates;
