import { useTranslation } from 'react-i18next';

import ScreenContainer from 'components/containers/ScreenContainer';

import GenerateCodeForm from 'components/GenerateAccessForm';
import useVehicles from 'hooks/useVehicles';
import useSponsor from 'hooks/useSponsor';

function GenerateCode() {
	const { t } = useTranslation();

	const { loading, vehicles } = useVehicles();
	const { sponsor } = useSponsor();

	return (
		<ScreenContainer title={t('access:generate_code')}>
			<GenerateCodeForm sponsor={sponsor} vehicles={vehicles} loading={loading} />
		</ScreenContainer>
	);
}

export default GenerateCode;
