import { getSponsor } from 'lib/models/sponsor';
import { useCallback, useEffect, useState } from 'react';
function useSponsor() {
	const [loading, setLoading] = useState(true);
	const [sponsor, setSponsor] = useState('');

	const refetch = useCallback(async () => {
		const response = await getSponsor();
		if (response) {
			setSponsor(response);
		}
		setLoading(false);
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, sponsor, refetch, setLoading };
}

export default useSponsor;
