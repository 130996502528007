import { Box, Grid, Typography } from '@mui/material';
import QRCode from 'react-qr-code';
import style from './AccessTickets.module.css';
import { formatCUIT } from 'lib/helpers';

interface Props {
	access: Access;
	seeToggleButton: boolean;
	logoMute: string;
	sponsor: string;
	onChangeStatus: (id?: string) => void;
}
const ZebraAccess = ({ access, seeToggleButton, logoMute, onChangeStatus, sponsor }: Props) => {
	// const { t } = useTranslation();

	const spanishStays: { [key: string]: string } = {
		night: 'Noche',
		day: 'Día',
		tent: 'Carpa',
		supplier: 'Proveedor',
	};

	return (
		<Grid lg={4} md={10} item alignContent={'center'} justifyContent={'center'}>
			<Box
				className="zebraTicket"
				sx={{
					width: '2.36in',
					height: '3.15107in',
					maxWidth: '2.36in',
					backgroundColor: 'none',
					mr: 2,
					padding: '0.06in',
					border: '1px solid black',
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Box
					sx={{
						width: '100%',
						height: '0.40in',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignContent: 'center',
						mb: '0.09in',
					}}
				>
					<Box>
						<img
							style={{ height: '100%', width: 'auto', borderRight: '1px solid black' }}
							src={logoMute}
							alt="Logo de Mute"
						/>
						{sponsor && (
							<img src={sponsor} style={{ width: '100%', height: 'auto', verticalAlign: 'center' }} alt="sponsor" />
						)}
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
						<Typography textAlign={'end'} lineHeight={1.2} fontSize={12} variant="caption">
							{['tent', 'supplier'].includes(access.stay) ? spanishStays[access.stay] : access.type}
						</Typography>
						<Typography textAlign={'end'} lineHeight={1.2} fontSize={12} variant="caption">
							{['tent', 'supplier'].includes(access.stay)
								? `ID: ${formatCUIT(access.tent)}`
								: `${spanishStays[access.stay]}`}
						</Typography>
					</Box>
				</Box>

				<Box sx={{ width: '100%' }}>
					<div className={style.qrContainer}>
						<div
							style={{
								border: '3px solid black',
								padding: '3px',
								display: 'flex',
								justifyContent: 'center',
								alignContent: 'center',
							}}
						>
							<QRCode size={120} value={access.code} />
						</div>
						<Typography variant="caption" fontSize={14}>
							{access.code}
						</Typography>
					</div>
					<Typography className={style.notValidZebra} fontSize={8} variant="caption">
						Ticket no válido como factura
					</Typography>
					{['tent', 'supplier'].includes(access.stay) ? (
						<Typography className={style.dateRangeZebra} fontSize={'0.13in'} variant="caption">
							{`${parseDate(access.validDate.from)} a ${parseDate(access.validDate.to)}`}
						</Typography>
					) : (
						<Typography className={style.dateZebra} fontSize={'0.15in'} variant="caption">
							{`${parseDate(access.validDate.from)}`}
						</Typography>
					)}
				</Box>
				<div style={{ display: 'flex', flex: 1 }}></div>
				<Box>
					<Typography fontSize={8}>
						El establecimiento no se responsabiliza por roturas, faltantes,robos o hurtos.
					</Typography>
					<Typography fontSize={8}>La seguridad de los vehículos es responsabilidad de sus titulares.</Typography>
					<Typography fontSize={8}>La casa se reserva el derecho de admisión y permanencia</Typography>
					<Typography fontSize={8}>El estacionamiento no incluye el uso de baños</Typography>
				</Box>
			</Box>
		</Grid>
	);
};

export default ZebraAccess;

function parseDate(dateString?: string) {
	if (dateString) {
		const date = new Date(dateString);
		const day = String(date.getDate()).padStart(2, '0');
		const month = months[date.getMonth()];
		const year = String(date.getFullYear()).slice(-2);

		return `${day}-${month}-${year}`;
	}
	return '-';
}

const months: { [key: number]: string } = {
	0: 'ene',
	1: 'feb',
	2: 'mar',
	3: 'abr',
	4: 'may',
	5: 'jun',
	6: 'jul',
	7: 'ago',
	8: 'sep',
	9: 'oct',
	10: 'nov',
	11: 'dic',
};
