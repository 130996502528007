import { API_DOMAIN, SPONSOR_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';
import { zFile } from './file';

export async function getSponsor(): Promise<string> {
	try {
		const url = `${API_DOMAIN}${SPONSOR_URL}/image`;
		const response = await api.get(url);

		const data = zFile.safeParse(response.data);

		return data.success ? `data:image/png;base64,${data.data.file}` : '';
	} catch (error) {
		console.error(error);
		return '';
	}
}

export async function changeSponsor(image: File): Promise<string | null> {
	try {
		const formData = new FormData();
		formData.append('file', image);
		const url = `${API_DOMAIN}${SPONSOR_URL}`;
		const response = await api.post<{ image: string }>(url, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data.image;
	} catch (error) {
		console.error(error);
		return null;
	}
}
