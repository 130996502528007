import { Button } from '@mui/material';
import { InvalidScanModal, ManuallyExit, OfflineScanning, SuccessfulScan } from 'components/Modals';
import { enqueueSnackbarError, enqueueSnackbarSuccess } from 'lib/helpers';
import { saveExit, syncScans, verifyCodeDateRangeOnExit } from 'lib/models/access';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import QRReader from 'components/QRReader/QRReader';
import { AccessModals } from 'enums/access';
import useServerDown from 'hooks/useServerDown';

function ExitScanner() {
	const { t } = useTranslation();
	const isDown = useServerDown();

	const found = useRef('');
	const [access, setAccess] = useState<Access | undefined>();
	const [openModal, setOpenModal] = useState<string | null>(null);
	const [flash, setFlash] = useState<string | null>(null);
	const [code, setCode] = useState<string>('');
	const [offlineResults, setOfflineResults] = useState([]);
	const [isOut, setIsOut] = useState<'date' | 'hour' | undefined>(undefined);
	const [invalidScanError, setInvalidScanError] = useState<'alreadyUsed'>();

	const closeAll = () => {
		setOpenModal(null);
		found.current = '';
		setCode('');
		setAccess(undefined);
		setInvalidScanError(undefined);
	};

	const handleFlash = (color: string) => {
		setFlash(color);

		setTimeout(() => {
			setFlash(null);
		}, 500);
	};

	const verifyExit = async (code: string, isDown?: boolean) => {
		if (!!code && !found.current) {
			found.current = code;
			if (isDown) {
				setCode(code);
				return setOpenModal(AccessModals.OfflineScanning);
			}
			const response = await verifyCodeDateRangeOnExit(code);

			if (typeof response === 'string') {
				enqueueSnackbarError(t(`${response}`));
				handleFlash('red');
				setTimeout(() => {
					closeAll();
				}, 1000);
				return;
			}

			const { access, alreadyUsed, outOfDate, outOfHour } = response;
			if (access) setAccess(access);

			if (access.vehiclesInside === 0) {
				enqueueSnackbarError(t(`access:accessWithoutPrevEntry`));
				handleFlash('red');
				setTimeout(() => {
					closeAll();
				}, 1000);
				return;
			}

			if (alreadyUsed) {
				setInvalidScanError('alreadyUsed');
				setOpenModal(AccessModals.InvalidScan);
				enqueueSnackbarError('QR ya utilizado');
				return handleFlash('red');
			}

			if (outOfDate || outOfHour) {
				setIsOut(outOfDate ? 'date' : 'hour');
				handleFlash('yellow');
				return setOpenModal(AccessModals.SuccessfulScan);
			}

			handleFlash('green');
			return setOpenModal(AccessModals.SuccessfulScan);
		}
	};

	const onSaveExit = async ({ code, plate }: { code?: string; plate?: string }) => {
		try {
			if (plate || code) {
				await saveExit({ code, plate });
				if (!access) {
					handleFlash('green');
				}
				enqueueSnackbarSuccess(t('access:exitSuccess'));
			}
		} catch (error) {
			handleFlash('red');
			enqueueSnackbarError(error);
		}
		closeAll();
	};

	const modalProps = {
		open: openModal,
		handleClose: closeAll,
		access,
	};

	const modals: Record<string, React.ReactNode> = {
		OfflineScanning: (
			<OfflineScanning
				{...modalProps}
				code={code}
				route={'exit'}
				result={offlineResults}
				setResult={setOfflineResults}
			/>
		),
		ManuallyExit: <ManuallyExit {...modalProps} onVerify={verifyExit} />,
		SuccessfulScan: <SuccessfulScan {...modalProps} onSave={onSaveExit} isEntry={false} isOut={isOut} />,
		InvalidScanModal: <InvalidScanModal {...modalProps} errorType={invalidScanError} />,
	};

	const syncStoredScan = async () => {
		const response = await syncScans();

		if (!response) return enqueueSnackbarSuccess(t('access:errorOnSync'));

		if (response.length === 0) return enqueueSnackbarSuccess(t('access:noEnqueuedScans'));

		setOfflineResults(response);
		setOpenModal(AccessModals.OfflineScanning);
	};

	return (
		<>
			{!isDown && (
				<Button
					variant="contained"
					onClick={syncStoredScan}
					color="primary"
					sx={{ position: 'absolute', right: '.5rem', top: '.5rem', zIndex: 50 }}
				>
					{t('access:sync')}
				</Button>
			)}

			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100vw',
					height: '100vh',
					zIndex: 20,
					backgroundColor: flash || 'none',
					opacity: flash === null ? 0 : 1,
					pointerEvents: 'none',
					transition: 'opacity 0.4s ease-in-out',
				}}
			></div>

			<QRReader
				modalName={AccessModals.ManuallyExit}
				onScan={verifyExit}
				routeName="scan_exit"
				setOpenModal={setOpenModal}
			/>
			{/* MODALS */}
			{openModal && modals[openModal]}
		</>
	);
}

export default ExitScanner;
