import { API_DOMAIN, COMMENTS } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus, getSearch } from 'lib/helpers';

import { z } from 'zod';

export const zAppComment = z.object({
	_id: z.optional(z.string()),
	username: z.string(),
	comment: z.string(),
	createdAt: z.optional(z.string()),
	user: z
		.object({
			username: z.string(),
			email: z.string(),
			first_name: z.string(),
			last_name: z.string(),
		})
		.optional(),
});

const zAppCommentsArrayResponse = z.object({
	total: z.number(),
	// We only need to validate the first element, if its ok, we assume that the rest are ok
	elements: z.custom<AppComment[]>((data) => {
		if (Array.isArray(data) && data.length > 0) {
			const parsed = zAppComment.safeParse(data[0]);
			if (!parsed.success) {
				console.error(`${parsed.error}`);
			}
			return parsed.success;
		}
		if (!Array.isArray(data)) {
			console.error(`response should be a valid array`);
			return false;
		}
		return true;
	}),
});

export type FetchAppCommentSearchProps = {
	search?: string | null;
};

export type FetchAppCommentProps = FetchAppCommentSearchProps & PaginationProps;

export async function fetchAppComments(props: FetchAppCommentProps): Promise<PaginateSource<AppComment>> {
	try {
		const sort: Sort = {
			[props.orderBy || 'comment']: props.order || 'asc',
		};

		const { filter, options } = getSearch(sort, props);

		console.debug(`${API_DOMAIN}${COMMENTS}`);

		const response = await api.get(`${API_DOMAIN}${COMMENTS}`, {
			params: { filter, options },
		});
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = zAppCommentsArrayResponse.parse(response.data);
		return data;
	} catch (error) {
		console.error(error);
		return { elements: [], total: 0 };
	}
}
