import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, MenuItem, Select, Typography } from '@mui/material';

import ProductModal from 'components/ProductModal/ProductModal';
import SearchToolbar from 'components/SearchToolbar';
import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';

import useProducts from 'hooks/useProducts';
import ProductsList from 'components/ProductsList/ProductsList';
import useProductCategories from 'hooks/useProductCategories';

export default function Products() {
	const defaultProduct: Product = {
		title: '',
		categories: [],
		price: 0,
	};

	const { t } = useTranslation();

	const title = t('product:products');

	const { productCategories, productCategoriesMap, refetch: refetchCategories } = useProductCategories();

	const [showModal, setShowModal] = useState<Product | null>(null);

	const [searchParams, setSearchParams] = useSearchParams({ search: '', page: '0', rowsPerPage: '10000', status: '' });

	const [category, setCategory] = useState<string>('all');

	const [search, setSearch] = useState<string>('');

	const [page, setPage] = useState<number>(Number(searchParams.get('page')) || 0);

	const [rowsPerPage, setRowsPerPage] = useState<number>(Number(searchParams.get('rowsPerPage')) || 10000);

	const searchProps = useMemo(() => {
		let searchParams = { categories: category === 'all' ? '' : category, search, page, rowsPerPage };
		return searchParams;
	}, [category, search, page, rowsPerPage]);

	const productSearch = useMemo(() => {
		return { ...searchProps, orderBy: 'price', order: 'asc' as 'asc' };
	}, [searchProps]);

	const handleChangePage = (_: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(e.target.value, 10));
		setPage(0);
	};

	const onClose = () => {
		refetch();
		setShowModal(null);
	};

	const onEdit = (product: Product) => {
		setShowModal(product);
	};

	const onChange = (productCategoryId: string) => {
		setCategory(productCategoryId);
	};

	const onSearch = (e: string) => {
		setSearch(e);
	};

	useEffect(() => {
		setSearchParams({
			page: `${page}`,
			rowsPerPage: `${rowsPerPage}`,
			category: `${category === 'all' ? '' : category}`,
		});
	}, [setSearchParams, page, rowsPerPage, category]);

	const { loading, products, refetch: refetchProducts } = useProducts(productSearch);

	const refetch = () => {
		refetchCategories();
		refetchProducts();
	};

	return (
		<ScreenContainer title={title} goBack={true}>
			<CreateIcon onCreate={() => setShowModal(defaultProduct)} disabled={loading} />
			{showModal && <ProductModal defaultValue={showModal} onClose={onClose} />}
			<Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
				<Select sx={{ minWidth: '15vw' }} value={category} onChange={(ev) => onChange(ev.target.value)}>
					<MenuItem value="all">Seleccionar categoría</MenuItem>
					{productCategories.map((productCategory) => {
						return (
							<MenuItem
								key={`category-${productCategory._id}`}
								value={productCategory._id}
								sx={{
									borderBottomColor: 'lightgray',
									borderBottomWidth: 1,
									borderBottomStyle: 'solid',
								}}
							>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<Typography>{productCategory.title}</Typography>
									{productCategory.description ?? (
										<Typography variant="caption" sx={{ color: 'gray' }}>
											{productCategory.description}
										</Typography>
									)}
								</Box>
							</MenuItem>
						);
					})}
				</Select>

				<SearchToolbar onSearch={onSearch} initialValue={search} />
			</Box>

			<Box>
				<Accordion defaultExpanded={true}>
					<AccordionSummary expandIcon={<ExpandMore />} aria-controls="panella-content" id="panella-header">
						<Typography>{t('common:product')}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<ProductsList
							onEdit={onEdit}
							productCategoriesMap={productCategoriesMap}
							refetch={refetch}
							data={products}
							loading={loading}
							page={page}
							rowsPerPage={rowsPerPage}
							handleChangePage={handleChangePage}
							handleChangeRowsPerPage={handleChangeRowsPerPage}
						/>
					</AccordionDetails>
				</Accordion>
			</Box>
		</ScreenContainer>
	);
}
