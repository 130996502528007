import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
	Button,
	FormGroup,
	TextField,
	Box,
	Select,
	MenuItem,
	LinearProgress,
	FormControl,
	InputLabel,
	Grid,
} from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { enqueueSnackbarError } from 'lib/helpers';
import AccessTickets from 'components/AccessTickets';
import moment, { Moment } from 'moment';
import { saveAccess, saveAccessTent } from 'lib/models/access';
import useUser from 'hooks/useUser';

type Props = {
	vehicles: Vehicle[];
	loading: boolean;
	sponsor: string;
};

function GenerateAccessForm({ vehicles, sponsor }: Props) {
	const { t } = useTranslation();

	const { user } = useUser();

	const isAdmin = user?.type === 'admin' || user?.type === 'parkingAdmin';

	const [isPrinting, setIsPrinting] = useState<boolean>(false);

	const [loading, setLoading] = useState<boolean>(false);

	const [quantity, setQuantity] = useState<number | ''>(1);

	const [accessCodeInfo, setAccessCodeInfo] = useState<Access>(getDefaultAccess());

	const [accessCodes, setAccessCodes] = useState<Access[]>([]);

	const [startDate, setStartDate] = useState<Moment | null>(moment());

	const [endDate, setEndDate] = useState<Moment | null>(moment());

	const [canSave, setCanSave] = useState<boolean>(false);

	const [touched, setTouched] = useState(false);

	const CUITerror =
		touched &&
		accessCodeInfo.stay === 'supplier' &&
		(!accessCodeInfo.tent || accessCodeInfo.tent.length !== 11 || isNaN(Number(accessCodeInfo.tent)));

	useEffect(() => {
		const { stay, tent, type, description } = accessCodeInfo;
		const today = moment();
		const sameDay = startDate?.isSame(today, 'day') && endDate?.isSame(today, 'day');
		const tentConditions =
			(stay === 'tent' || stay === 'supplier') &&
			!!tent &&
			!!description &&
			!!startDate &&
			!!endDate &&
			isAdmin &&
			!CUITerror;
		const nonTentConditions = stay !== 'tent' && type && isAdmin;

		if (tentConditions || nonTentConditions || (stay !== 'tent' && type && sameDay)) {
			setCanSave(true);
		} else {
			setCanSave(false);
		}
	}, [accessCodeInfo, isAdmin, endDate, startDate, CUITerror]);

	const onUpdate = (key: keyof Access) => {
		return (event: any) => {
			const value = event.target.value;

			if (accessCodeInfo) {
				setAccessCodeInfo({ ...accessCodeInfo, [key]: value });
			}
		};
	};

	const onUpdateStay = (stay: string) => {
		setAccessCodeInfo({ ...getDefaultAccess(), stay, type: '' });
		setQuantity(1);
	};

	const onUpdateDate = (date: Moment | null, key: keyof ValidDate) => {
		if (accessCodeInfo && date) {
			const updated = { ...accessCodeInfo };

			updated.validDate[key] = new Date(date.format()).toString();

			setAccessCodeInfo(updated);
		}
	};

	const onUpdatePlates = (plate: string, index: number) => {
		const uppercasePlate = plate.toUpperCase();

		setAccessCodeInfo((prev) => {
			let newPlates = prev.plate;
			newPlates[index] = uppercasePlate;

			return { ...prev, plate: newPlates };
		});
	};

	const save = async () => {
		try {
			setLoading(true);
			if (!startDate || !endDate) {
				throw new Error('Invalid dates');
			}
			const start = startDate.toISOString();
			const end = endDate.toISOString();

			const { type, stay, tent, email, plate, slots, description } = accessCodeInfo;

			const onDayStay = stay === 'day' || stay === 'night';
			const longStay = stay === 'tent' || stay === 'supplier';

			if (onDayStay && quantity) {
				const accessCodes = await saveAccess(start, end, stay, quantity, type);
				setAccessCodes(accessCodes);
				setIsPrinting(true);
			}

			if (longStay && tent) {
				const accessCode = await saveAccessTent(start, end, stay, type, tent, plate, slots, email, description);
				setAccessCodes([accessCode]);
				setIsPrinting(true);
			}
		} catch (error) {
			enqueueSnackbarError(error);
		}
		setLoading(false);
	};

	const handleBlur = () => {
		setTouched(true);
	};

	return (
		<Box>
			{loading && <LinearProgress />}
			{!isPrinting && (
				<FormGroup sx={{ pb: 4 }}>
					<FormControl>
						<InputLabel id="stay">Estadía</InputLabel>

						<Select
							labelId="stay"
							id="stay"
							label={t('access:stay')}
							fullWidth={true}
							value={accessCodeInfo.stay || 'day'}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={(e) => onUpdateStay(e.target.value)}
							autoComplete="off"
						>
							<MenuItem value={'day'}>{t('access:day')}</MenuItem>
							<MenuItem value={'night'}>{t('access:night')}</MenuItem>
							{isAdmin && <MenuItem value={'tent'}>{t('access:tent')}</MenuItem>}
							{isAdmin && <MenuItem value={'supplier'}>{'Proveedor'}</MenuItem>}
						</Select>
					</FormControl>
					{accessCodeInfo.stay !== 'tent' && accessCodeInfo.stay !== 'supplier' && (
						<>
							<TextField
								type="number"
								inputProps={{ min: 1 }}
								autoComplete="off"
								id="quantity"
								label={t('access:quantityPerDay')}
								fullWidth={true}
								value={quantity}
								variant="outlined"
								sx={{ mb: 3 }}
								onChange={(e) => {
									const value = Number(e.target.value);
									setQuantity(value === 0 ? '' : value);
								}}
							/>
							<DatePicker
								sx={{ marginBottom: '24px' }}
								value={startDate}
								format="DD/MM/YYYY"
								label={t('access:from')}
								onChange={(ev) => {
									setStartDate(ev);
								}}
								minDate={moment()}
								disabled={!isAdmin}
							/>
							<DatePicker
								sx={{ marginBottom: '24px' }}
								value={endDate}
								format="DD/MM/YYYY"
								label={t('access:to')}
								onChange={(ev) => {
									setEndDate(ev);
								}}
								minDate={startDate}
								disabled={!isAdmin}
							/>
						</>
					)}
					{(accessCodeInfo.stay === 'tent' || accessCodeInfo.stay === 'supplier') && (
						<>
							<TextField
								autoComplete="off"
								id="tent"
								label={accessCodeInfo.stay === 'tent' ? 'Número de carpa' : 'Número de CUIT'}
								fullWidth={true}
								value={accessCodeInfo.tent}
								variant="outlined"
								sx={{ mb: 3 }}
								onChange={onUpdate('tent')}
								error={CUITerror}
								helperText={
									CUITerror && isNaN(Number(accessCodeInfo.tent))
										? 'El CUIT debe ser numérico, sin letras ni guiones'
										: CUITerror
										? 'El CUIT debe tener 11 dígitos'
										: null
								}
								onBlur={handleBlur}
							/>
							<TextField
								autoComplete="off"
								id="email"
								label={t('common:Email')}
								fullWidth={true}
								value={accessCodeInfo.email}
								variant="outlined"
								sx={{ mb: 3 }}
								onChange={onUpdate('email')}
							/>
							<TextField
								autoComplete="off"
								id="description"
								label={accessCodeInfo.stay === 'tent' ? 'Nombre y apellido' : 'Razón social'}
								fullWidth={true}
								value={accessCodeInfo.description}
								variant="outlined"
								sx={{ mb: 3 }}
								onChange={onUpdate('description')}
							/>
							<TextField
								type="number"
								autoComplete="off"
								id="slots"
								label={t('access:slots')}
								fullWidth={true}
								value={accessCodeInfo.slots}
								variant="outlined"
								sx={{ mb: 3 }}
								onChange={onUpdate('slots')}
							/>
							{!isNaN(accessCodeInfo.slots) && (
								<Grid container spacing={2} columns={10} sx={{ mb: 3 }} alignItems="center" width={'full'}>
									{Array.from({ length: accessCodeInfo.slots }, (_, i) => i).map((n) => (
										<Grid item xs={5} lg={2}>
											<TextField
												type="text"
												autoComplete="off"
												id={`slot${n + 1}`}
												label={`Patente ${n + 1}`}
												fullWidth={true}
												value={accessCodeInfo.plate[n]}
												variant="outlined"
												onChange={(e) => onUpdatePlates(e.target.value, n)}
											/>
										</Grid>
									))}
								</Grid>
							)}

							<DatePicker
								sx={{ marginBottom: '24px' }}
								value={startDate}
								format="DD/MM/YYYY"
								label={t('access:start_date')}
								onChange={(ev) => {
									onUpdateDate(ev, 'from');
									setStartDate(ev);
								}}
								minDate={moment()}
							/>
							<DatePicker
								sx={{ marginBottom: '24px' }}
								value={endDate}
								format="DD/MM/YYYY"
								label={t('access:end_date')}
								onChange={(ev) => {
									onUpdateDate(ev, 'to');
									setEndDate(ev);
								}}
								minDate={startDate ? moment(startDate) : moment()}
							/>
						</>
					)}
					{accessCodeInfo.stay !== 'tent' && accessCodeInfo.stay !== 'supplier' && (
						<FormControl fullWidth>
							<InputLabel id="type-label">Tipo de vehículo</InputLabel>
							<Select
								labelId="type"
								id="type"
								label="Tipo de vehículo"
								fullWidth={true}
								value={accessCodeInfo.type}
								variant="outlined"
								sx={{
									mb: 3,
									'& .MuiInputLabel-root': {
										color: 'blue',
									},
								}}
								onChange={onUpdate('type')}
							>
								{vehicles?.map((vehicle) => {
									return (
										<MenuItem value={vehicle.description} selected={true}>
											{vehicle.description}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					)}

					<Button
						variant="contained"
						color="primary"
						size="large"
						sx={{ m: 1 }}
						startIcon={<SaveIcon />}
						onClick={save}
						disabled={!canSave}
					>
						{t('access:generate')}
					</Button>
				</FormGroup>
			)}
			{isPrinting && (
				<AccessTickets
					date={{ start: startDate?.format('DD-MM-YYYY'), end: endDate?.format('DD-MM-YYYY') }}
					accessCodes={accessCodes}
					loading={loading}
					setLoading={setLoading}
					sponsor={sponsor}
				/>
			)}
		</Box>
	);
}

export default GenerateAccessForm;

function getDefaultAccess(stay?: string, type?: string): Access {
	return {
		code: '',
		plate: [],
		stay: stay || 'day',
		type: type || '',
		tent: '',
		email: '',
		validDate: {},
		movements: [],
		vehiclesInside: 0,
		isActive: true,
		slots: 1,
		description: '',
	};
}
