import { useCallback, useEffect, useState } from 'react';

import { FetchCandidatesProps } from 'lib/models/candidates';
import { fetchAppComments } from 'lib/models/appComments';

function useAppComments(props: FetchCandidatesProps) {
	const [loading, setLoading] = useState<boolean>(true);

	const [comments, setComments] = useState<PaginateSource<AppComment>>({ total: 0, elements: [] });

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchAppComments(props);
		setComments(response);
		setLoading(false);
	}, [props]);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, comments, refetch };
}

export default useAppComments;
