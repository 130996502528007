import { API_DOMAIN, PRODUCTS_CATS_URL, PRODUCTS_MIXERS_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';
import { z } from 'zod';
import { zProduct } from './products';

export const zProductCategory = z.object({
	_id: z.optional(z.string()),
	title: z.string(),
	description: z.optional(z.string()),
	items: z.array(z.string()),
	vip: z.optional(z.boolean()),
});

export const zPopulatedProductCategory = z.object({
	_id: z.optional(z.string()),
	title: z.string(),
	description: z.optional(z.string()),
	items: z.array(zProduct),
	vip: z.optional(z.boolean()),
});

export async function fetchProductMixers() {
	try {
		const response = await api.get(`${API_DOMAIN}${PRODUCTS_MIXERS_URL}`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = z.optional(z.array(z.string())).parse(response.data);
		return data || ([] as string[]);
	} catch (error) {
		console.error(error);
		return [] as string[];
	}
}

export async function fetchProductCategories() {
	try {
		const response = await api.get(`${API_DOMAIN}${PRODUCTS_CATS_URL}`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = z.array(zProductCategory).parse(response.data);
		return data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function fetchPopulatedProductCategories() {
	try {
		const response = await api.get(`${API_DOMAIN}${PRODUCTS_CATS_URL}?populate=items`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = z.array(zPopulatedProductCategory).parse(response.data);
		return data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function createProductCategory(category: ProductCategory | PopulatedProductCategory) {
	// Remove items before updating category
	const postData: Partial<ProductCategory | PopulatedProductCategory> = { ...category };
	delete postData.items;

	const response = await api.post(`${API_DOMAIN}${PRODUCTS_CATS_URL}`, postData);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	const data = zProductCategory.safeParse(response.data);
	if (data.success) {
		return data.data;
	}
	return null;
}

export async function updateProductCategory(category: ProductCategory | PopulatedProductCategory) {
	// Remove items before updating category
	const postData: Partial<ProductCategory | PopulatedProductCategory> = { ...category };
	delete postData.items;

	const response = await api.patch(`${API_DOMAIN}${PRODUCTS_CATS_URL}/${category._id}`, postData);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	const data = zProductCategory.safeParse(response.data);
	if (data.success) {
		return data.data;
	}
	return null;
}

export async function deleteProductCategory(
	category: ProductCategory | PopulatedProductCategory
): Promise<boolean | null> {
	try {
		const response = await api.delete(`${API_DOMAIN}${PRODUCTS_CATS_URL}/${category._id}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response?.data?._id === category._id;
	} catch (error) {
		return null;
	}
}
