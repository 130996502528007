import { Grid, LinearProgress } from '@mui/material';
import DownloadPDFIcon from 'components/icons/DownloadPDFIcon';
import { Format } from 'components/screens/parking/QRCodes/QRCodes';
import useUser from 'hooks/useUser';
import { enqueueSnackbarError, enqueueSnackbarSuccess } from 'lib/helpers';
import { changeStatus } from 'lib/models/access';
import { Dispatch, SetStateAction, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import logoMute from '../../assets/images/mute.png';
//@ts-ignore
import html2pdf from 'html2pdf.js';
import A5Access from './A5Access';
import ZebraAccess from './ZebraAccess';
import style from './AccessTickets.module.css';

type Props = {
	accessCodes: Access[];
	sponsor: string;
	format?: Format;
	date?: { start?: string; end?: string };
	loading?: boolean;
	setLoading?: Dispatch<SetStateAction<boolean>>;
	marginTop?: string;
	refetch?: () => void;
};

function AccessTickets({ accessCodes, date, loading, setLoading, refetch, sponsor, format }: Props) {
	const { t } = useTranslation();
	const location = useLocation();

	const { user } = useUser();

	const isAdmin = user?.type === 'admin' || user?.type === 'parkingAdmin';

	const route = location.pathname;

	const seeToggleButton = isAdmin && route !== '/generate';

	const onChangeStatus = async (id?: string) => {
		try {
			if (!id) throw new Error('Missing ID');
			const response = await changeStatus(id);

			if (!response) {
				throw new Error();
			}

			if (refetch) {
				refetch();
			}

			enqueueSnackbarSuccess(t('access:updatedAccess'));
		} catch (error) {
			enqueueSnackbarError(t('access:errorOnUpdate'));
		}
	};
	const contentRef = useRef<HTMLDivElement>(null);

	const generatePDF = async () => {
		try {
			if (contentRef.current && setLoading) {
				setLoading(true);
				const buttons = document.querySelectorAll('.changeAccessStatus');
				buttons.forEach((button) => {
					//@ts-ignore
					button.style.display = 'none';
				});
				let pdfNumber = 1;

				if (format === '1xp') {
					const chunkSize = 100;
					const originalElement = contentRef.current;
					const originalHTML = originalElement.outerHTML;

					const clonedHTML = document.createElement('div');
					clonedHTML.innerHTML = originalHTML;

					const childDivs = Array.from(clonedHTML.querySelectorAll(`.zebraTicket`));

					for (let i = 0; i < childDivs.length; i += chunkSize) {
						const chunkContainer = document.createElement('div');
						const chunk = childDivs.slice(i, i + chunkSize);

						chunk.forEach((div) => {
							//@ts-ignore
							div.style.border = '3px solid black';
							//@ts-ignore
							div.style.margin = '0';

							chunkContainer.appendChild(div);
						});

						clonedHTML.appendChild(chunkContainer);

						await html2pdf()
							.set({
								filename: `Accesos_${pdfNumber}.pdf`,
								html2canvas: { scale: 2 },
								jsPDF: {
									unit: 'in',
									format: [3.15, 2.36],
									orientation: 'portrait',
								},
							})
							.from(chunkContainer)
							.save();

						pdfNumber++;
					}
				}
				if (format === '6xp' || !format) {
					const equalDates = date?.end === date?.start;

					const chunkSize = 100;

					const originalElement = contentRef.current;
					const originalHTML = originalElement.outerHTML;

					const clonedHTML = document.createElement('div');
					clonedHTML.innerHTML = originalHTML;

					const childDivs = Array.from(clonedHTML.querySelectorAll(`.${style.ticket}`));

					for (let i = 0; i < childDivs.length; i += chunkSize) {
						const positions = [3, 4, 5];
						let current = 0;
						const chunkContainer = document.createElement('div');
						chunkContainer.classList.add(style.ticketContainer);
						const chunk = childDivs.slice(i, i + chunkSize);

						chunk.forEach((div, i) => {
							if (div instanceof HTMLElement && positions.includes(current) && i < chunk.length - (chunk.length % 6)) {
								div.style.marginBottom = '130px';
							}
							div.classList.add(style.PTicket);

							const side = div.querySelector(`.${style.side}`);
							if (side) {
								side.classList.add(style.PSide);
							}

							const main = div.querySelector(`.${style.mainContent}`);
							if (main) {
								main.classList.add(style.PMainContent);
							}

							chunkContainer.appendChild(div);
							if (current === 5) current = 0;
							else current++;

							const muteLogo = div.querySelector(`.${style.muteLogo}`);
							if (muteLogo) {
								muteLogo.classList.add(style.PMuteLogo);
							}

							const sponsorContainer = div.querySelector(`.${style.sponsorContainer}`);
							if (sponsorContainer) {
								sponsorContainer.classList.add(style.PSponsorContainer);
							}
						});
						clonedHTML.appendChild(chunkContainer);
						await html2pdf()
							.from(chunkContainer)
							.set({
								margin: 0,
								filename: `Accesos_${equalDates ? date?.start : date?.start + '_' + date?.end}_${pdfNumber}.pdf`,
								html2canvas: { scale: 3 },
								jsPDF: {
									unit: 'mm',
									format: [220, 340],
									orientation: 'landscape',
								},
							})
							.save();
						pdfNumber++;
					}
				}
			}
		} catch (error) {
			console.error('Error al generar el PDF:', error);
			enqueueSnackbarError(t('access:errorOnPDF'));
		} finally {
			const buttons = document.querySelectorAll('.changeAccessStatus');
			buttons.forEach((button) => {
				//@ts-ignore
				button.style.display = 'flex';
			});

			if (setLoading) setLoading(false);
		}
	};

	return (
		<>
			{loading && <LinearProgress />}
			<DownloadPDFIcon onClick={generatePDF} />

			<Grid sx={{ mt: 2 }} container columns={12} justifyContent={'center'} rowSpacing={2} ref={contentRef}>
				{accessCodes.map((a) => {
					return format === '6xp' || !format ? (
						<A5Access
							access={a}
							logoMute={logoMute}
							onChangeStatus={onChangeStatus}
							seeToggleButton={seeToggleButton}
							sponsor={sponsor}
						/>
					) : (
						<ZebraAccess
							access={a}
							logoMute={logoMute}
							onChangeStatus={onChangeStatus}
							seeToggleButton={seeToggleButton}
							sponsor={sponsor}
						/>
					);
				})}
			</Grid>
		</>
	);
}

export default AccessTickets;
