import { fetchProductMixers } from 'lib/models/productCategories';
import { useCallback, useEffect, useState } from 'react';

function useProductMixers() {
	const [loading, setLoading] = useState<boolean>(false);

	const [mixers, setMixers] = useState<string[]>([]);

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchProductMixers();
		setMixers(response);
		setLoading(false);
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, mixers, refetch };
}

export default useProductMixers;
