import LoopIcon from '@mui/icons-material/Loop';
import { Button } from '@mui/material';
import { InvalidScanModal, ManuallyEntry, OfflineScanning, SuccessfulScan } from 'components/Modals';
import QRReader from 'components/QRReader/QRReader';
import { AccessModals } from 'enums/access';
import useServerDown from 'hooks/useServerDown';
import { enqueueSnackbarError, enqueueSnackbarSuccess } from 'lib/helpers';
import { saveEntry, syncScans, verifyCodeDateRangeOnEntry } from 'lib/models/access';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useStoreSelector from 'store/useStoreSelector';

function EntryScanner() {
	const { t } = useTranslation();
	const isDown = useServerDown();
	const scanQueue = JSON.parse(localStorage.getItem('scanQueue') || '[]');

	const dailyCodes = useStoreSelector((state) => state.dailyCodes);

	const found = useRef('');

	const [access, setAccess] = useState<Access | undefined>();
	const [openModal, setOpenModal] = useState<string | null>(null);
	const [flash, setFlash] = useState<string | null>(null);
	const [code, setCode] = useState<string>('');
	const [offlineResults, setOfflineResults] = useState([]);

	const [invalidScanError, setInvalidScanError] = useState<'alreadyUsed' | 'outOfDate' | 'fullSlots'>();

	const [isOut, setIsOut] = useState<'hour'>();

	const closeAll = () => {
		setOpenModal(null);
		setCode('');
		found.current = '';
		setAccess(undefined);
		setInvalidScanError(undefined);
		setIsOut(undefined);
	};

	const handleFlash = (color: string) => {
		setFlash(color);

		setTimeout(() => {
			setFlash(null);
		}, 500);
	};

	const verifyEntry = async (code: string, serverIsDown: boolean) => {
		if (!!code && !found.current) {
			found.current = code;

			if (serverIsDown) {
				setCode(code);

				const exists = dailyCodes.list.find((access) => access.code === code);

				if (!exists) {
					handleFlash('red');
					found.current = '';
					enqueueSnackbarError(t('access:invalidCode'));
					return;
				}

				handleFlash('green');
				return setOpenModal(AccessModals.OfflineScanning);
			}

			const response = await verifyCodeDateRangeOnEntry(code);

			if (typeof response === 'string') {
				enqueueSnackbarError(t(`${response}`));
				handleFlash('red');
				setTimeout(() => {
					closeAll();
				}, 1000);
				return;
			}

			const { access, outOfDate, alreadyUsed, fullSlots, outOfHour } = response;

			setAccess(access);

			if (alreadyUsed || outOfDate || fullSlots) {
				const errorType = alreadyUsed ? 'alreadyUsed' : outOfDate ? 'outOfDate' : 'fullSlots';
				const errorMessages = {
					alreadyUsed: t('access:alreadyUsed'),
					outOfDate: t('access:outOfDate'),
					fullSlots: `Máximo de vehículos alcanzado (${access.vehiclesInside}/${access.slots})`,
				};

				setInvalidScanError(errorType);
				setOpenModal(AccessModals.InvalidScan);
				enqueueSnackbarError(errorMessages[errorType]);

				handleFlash('red');
				return;
			}

			if (outOfHour) {
				handleFlash('yellow');
				setIsOut('hour');
			} else {
				handleFlash('green');
			}
			return setOpenModal(AccessModals.SuccessfulScan);
		}
	};

	const onSaveEntry = async ({ code, plate }: { code?: string; plate?: string }) => {
		try {
			if (!code) {
				throw new Error('Missing access code');
			}
			await saveEntry({ code, plate });
			if (!access) {
				handleFlash('green');
			}
			enqueueSnackbarSuccess(t('access:entrySuccess'));
		} catch (error) {
			handleFlash('red');
			enqueueSnackbarError(error);
		}
		closeAll();
	};

	const modalProps = {
		open: openModal,
		handleClose: closeAll,
		access,
	};

	const modals: Record<string, React.ReactNode> = {
		OfflineScanning: (
			<OfflineScanning
				{...modalProps}
				code={code}
				route={'entry'}
				result={offlineResults}
				setResult={setOfflineResults}
			/>
		),
		ManuallyEntry: <ManuallyEntry {...modalProps} onVerify={verifyEntry} />,
		SuccessfulScan: <SuccessfulScan {...modalProps} onSave={onSaveEntry} isEntry isOut={isOut} />,
		InvalidScan: <InvalidScanModal {...modalProps} errorType={invalidScanError} />,
	};

	const syncStoredScan = async () => {
		const response = await syncScans();

		if (!response) return enqueueSnackbarSuccess(t('access:errorOnSync'));

		if (response.length === 0) return enqueueSnackbarSuccess(t('access:noEnqueuedScans'));

		setOfflineResults(response);
		setOpenModal(AccessModals.OfflineScanning);
	};

	return (
		<>
			{!isDown && scanQueue && scanQueue.length > 0 && (
				<Button
					variant="contained"
					onClick={syncStoredScan}
					color="primary"
					sx={{ position: 'absolute', right: '.5rem', top: '.5rem', zIndex: 50 }}
				>
					<LoopIcon />
					{t('access:sync')}
				</Button>
			)}

			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100vw',
					height: '100vh',
					zIndex: 20,
					backgroundColor: flash || 'none',
					opacity: flash === null ? 0 : 0.8,
					pointerEvents: 'none',
					transition: 'opacity 0.4s ease-in-out',
					overflow: 'hidden',
				}}
			></div>
			<QRReader
				onScan={verifyEntry}
				setOpenModal={setOpenModal}
				modalName={AccessModals.ManuallyEntry}
				routeName="scan_entry"
			/>
			{/* MODALS */}
			{openModal && modals[openModal]}
		</>
	);
}

export default EntryScanner;
