import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Format } from 'components/screens/parking/QRCodes/QRCodes';
import Uploader from 'components/Uploader';
import useUser from 'hooks/useUser';
import { changeSponsor } from 'lib/models/sponsor';
import { Moment } from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { setSponsor } from 'store/reducers/sponsor';
import useStoreDispatch from 'store/useStoreDispatch';

type Props = {
	text: string;
	isAssigned: string;
	stay: string;
	from: Moment | null;
	to: Moment | null;
	onSearch: () => void;
	handleParams: (value: any, key: string) => void;
	refetch?: () => void;
	handleFormat?: (format: Format) => void;
	format?: Format;
};

function AccessFilter({
	text,
	isAssigned,
	stay,
	from,
	to,
	onSearch,
	handleParams,
	refetch,
	handleFormat,
	format,
}: Props) {
	const { t } = useTranslation();
	const dispatch = useStoreDispatch();
	const { user } = useUser();

	const isAdmin = user?.type === 'admin' || user?.type === 'parkingAdmin';

	const [showUploader, setShowUploader] = useState(false);

	const onCloseUploader = () => {
		setShowUploader(false);
	};

	async function uploadImage(file: File): Promise<boolean> {
		const response = await changeSponsor(file);

		if (response) {
			dispatch(setSponsor(response));
			return true;
		}

		return false;
	}

	const gridCols = handleFormat ? 12 : 10;

	return (
		// <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: '1rem' }}>
		<Grid container spacing={2} columns={gridCols} alignItems="center">
			{showUploader && (
				<Uploader id="sponsor-image" name="sponsor-file" upload={uploadImage} onClose={onCloseUploader} open={true} />
			)}
			<Grid item xs={10} sm={5} md={2}>
				<TextField
					fullWidth
					value={text}
					label={'Buscar'}
					onChange={(ev) => handleParams(ev.target.value.toUpperCase(), 'text')}
				/>
			</Grid>

			{handleFormat && format && (
				<Grid item xs={10} sm={5} md={2}>
					<FormControl fullWidth>
						<InputLabel>Formato</InputLabel>
						<Select
							labelId="format"
							id="format"
							label="Formato"
							value={format}
							onChange={(ev) => handleFormat(ev.target.value as Format)}
						>
							<MenuItem value={'6xp'}>{'6 x página'}</MenuItem>
							<MenuItem value={'1xp'}>{'1 x página'}</MenuItem>
						</Select>
					</FormControl>
				</Grid>
			)}

			<Grid item xs={10} sm={5} md={2}>
				<FormControl fullWidth>
					<InputLabel>Con patente</InputLabel>
					<Select
						labelId="plate"
						id="plate"
						label="Con patente"
						value={isAssigned}
						onChange={(ev) => handleParams(ev.target.value as string, 'isAssigned')}
					>
						<MenuItem value={'any'}>{t('common:any')}</MenuItem>
						<MenuItem value={'assigned'}>{t('access:assigned')}</MenuItem>
						<MenuItem value={'unassigned'}>{t('access:unassigned')}</MenuItem>
					</Select>
				</FormControl>
			</Grid>

			<Grid item xs={10} sm={5} md={2}>
				<FormControl fullWidth>
					<InputLabel>Estadía</InputLabel>
					<Select
						labelId="stay"
						id="stay"
						label="Estadía"
						value={stay}
						onChange={(ev) => handleParams(ev.target.value as string, 'stay')}
					>
						<MenuItem value={'all'}>{t('access:all')}</MenuItem>
						<MenuItem value={'day'}>{t('access:day')}</MenuItem>
						<MenuItem value={'night'}>{t('access:night')}</MenuItem>
						<MenuItem value={'tent'}>{t('access:tent')}</MenuItem>
						<MenuItem value={'supplier'}>{'Proveedor'}</MenuItem>
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={10} sm={5} md={2}>
				<DatePicker
					sx={{ width: '100%' }}
					value={from}
					label={t('common:from')}
					onChange={(v) => handleParams(v, 'from')}
					format="DD/MM/YYYY"
				/>
			</Grid>

			<Grid item xs={10} sm={5} md={2}>
				<DatePicker
					sx={{ width: '100%' }}
					value={to}
					minDate={from || undefined}
					label={t('common:to')}
					onChange={(v) => handleParams(v, 'to')}
					format="DD/MM/YYYY"
				/>
			</Grid>

			{/* <Button onClick={onSearch} variant="contained">
				{t('common:search')}
			</Button> */}
			<Box sx={{ textAlign: 'center', position: 'fixed', top: 100, right: 12, zIndex: 9999 }}>
				{isAdmin && (
					<Button
						sx={{ fontSize: '0.8rem', paddingX: '0.3rem', marginRight: '.2rem' }}
						variant="contained"
						component={Link}
						to="/scan/plate"
					>
						{t('access:assignPlate')}
					</Button>
				)}
				{isAdmin && (
					<Button
						onClick={() => {
							setShowUploader(true);
						}}
						sx={{ fontSize: '0.8rem', paddingX: '0.3rem' }}
						variant="contained"
					>
						{t('access:changeSponsor')}
					</Button>
				)}
			</Box>
		</Grid>
	);
}

export default AccessFilter;
