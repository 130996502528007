import { API_DOMAIN, SECTIONS_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';
import { z } from 'zod';
import { zPopulatedProductCategory } from './productCategories';

export enum SectionMode {
	events = 'events',
	beach = 'beach',
	all = 'all',
	unselected = '',
}

export enum SectionType {
	BAR = 'BAR',
	TENT = 'TENT',
	PARKING = 'PARKING',
	OTHER = 'OTHER',
}

const zSectionType = z.custom<SectionType>(
	(data) => typeof data === 'string' && ['BAR', 'TENT', 'PARKING', 'OTHER'].includes(data)
);

const zSectionMode = z
	.custom<SectionMode>((data) => typeof data === 'string' && ['events', 'beach', 'all', ''].includes(data))
	.optional();

export const zProductSection = z.object({
	_id: z.optional(z.string()),
	title: z.string(),
	categories: z.array(z.string()),
	// @TODO: Check if the possibility to disable items in each section is required
	// disabledItems: z.array(z.string()),
	allowsPromotionPrice: z.number(),
	type: zSectionType,
	mode: zSectionMode,
});

export const zPopulatedProductSection = z.object({
	_id: z.optional(z.string()),
	title: z.string(),
	categories: z.array(zPopulatedProductCategory),
	// @TODO: Check if the possibility to disable items in each section is required
	// disabledItems: z.array(z.string()),
	allowsPromotionPrice: z.boolean(),
	type: zSectionType,
	mode: zSectionMode,
});

export async function fetchSections() {
	try {
		const response = await api.get(`${API_DOMAIN}${SECTIONS_URL}`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = z.array(zProductSection).parse(response.data);
		return data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function fetchPopulatedSections() {
	try {
		const response = await api.get(`${API_DOMAIN}${SECTIONS_URL}`, {
			params: {
				populate: {
					path: 'categories',
					populate: { path: 'items' },
				},
			},
		});
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = z.array(zPopulatedProductSection).parse(response.data);
		return data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function createSection(section: ProductSection | PopulatedProductSection) {
	// Replace categories for ids
	const postData: Partial<ProductSection | PopulatedProductSection> = { ...section };
	postData.categories = postData.categories?.map((el) => {
		return typeof el === 'string' ? el : `${el._id}`;
	});

	const response = await api.post(`${API_DOMAIN}${SECTIONS_URL}`, postData);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	const data = zProductSection.safeParse(response.data);
	if (data.success) {
		return data.data;
	}
	return null;
}

export async function updateSection(section: ProductSection | PopulatedProductSection) {
	// Replace categories for ids
	const postData: Partial<ProductSection | PopulatedProductSection> = { ...section };
	postData.categories = postData.categories?.map((el) => {
		return typeof el === 'string' ? el : `${el._id}`;
	});

	const response = await api.patch(`${API_DOMAIN}${SECTIONS_URL}/${section._id}`, postData);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	const data = zProductSection.safeParse(response.data);
	if (data.success) {
		return data.data;
	}
	return null;
}

export async function deleteSection(section: ProductSection | PopulatedProductSection): Promise<boolean | null> {
	try {
		const response = await api.delete(`${API_DOMAIN}${SECTIONS_URL}/${section._id}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response?.data?._id === section._id;
	} catch (error) {
		return null;
	}
}
